import { defineComponent } from 'vue';
import AppCardRouterButton from '@/components/shared/AppCardRouterButton.vue';
import { CustomerRoutes } from '@/router/index';
import OrganizationsService from '@/services/OrganizationsService';
import { OrganizationsFilter } from '@/services/models/Organization';
import { OrganizationType } from '@/services/models/shared/OrganizationType';
import { UserAccountsFilter } from '@/services/models/UserAccount';
import BSAlert from '@/components/shared/bootstrap/Alert.vue';
import UsersService from '@/services/UsersService';
import AppInput from '@/components/shared/AppInput.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import { UserAccountStatus } from '@/services/models/shared/UserAccountStatus';
export default defineComponent({
    name: 'Forms',
    inject: ['userIsEmployee', 'userIsAdministrator'],
    components: {
        AppCardRouterButton,
        AppInput,
        AppSelect,
        BSButton,
        BSAlert
    },
    data() {
        return {
            organizationOptions: [],
            customerAccountOptions: [],
            selectedOrganization: null,
            selectedCustomerUserAccountId: null,
            currentSubmitter: {
                name: '',
                office: '',
                phoneNumber: '',
                faxNumber: '',
                emailAddress: ''
            }
        };
    },
    computed: {
        showFormList() {
            return this.$route.name === 'Forms';
        },
        routes() {
            var formsRoutes = CustomerRoutes.find((route) => {
                return route.name === 'Forms';
            });
            if (formsRoutes && formsRoutes.children) {
                if (!this.$store.getters.user.isTWC && !(this.userIsEmployee || this.userIsAdministrator)) {
                    return formsRoutes.children.filter((route) => {
                        return route.name !== 'Car Fax Request';
                    });
                }
                return formsRoutes.children;
            }
            return [];
        }
    },
    methods: {
        onCancel() {
            this.$router.push({ name: 'Forms' });
        },
        loadOrganizations() {
            const filter = new OrganizationsFilter(true);
            filter.onlyHasUsers = true;
            filter.organizationType = OrganizationType.activeCustomer;
            OrganizationsService.getOrganizations(filter.options())
                .then(response => {
                if (!response.data) {
                    this.organizationOptions = [];
                    return;
                }
                this.organizationOptions = response.data.map((e) => ({ item: e.name, value: e.id }));
            });
        },
        loadCustomers(organizationId) {
            this.selectedCustomerUserAccountId = null;
            const filter = new UserAccountsFilter(true);
            filter.organizationId = this.selectedOrganization;
            filter.userAccountStatus = UserAccountStatus.activeAuthorized;
            UsersService.getUserAccounts(filter.options())
                .then(response => {
                if (!response.data) {
                    this.customerAccountOptions = [];
                    return;
                }
                this.customerAccountOptions = response.data.map((e) => ({ item: e.fullName, value: e.id }));
            });
        },
        async getDelegateUser(id) {
            await this.$store.dispatch('setDelegateUser', id);
            UsersService.getUserAccount(id)
                .then(response => {
                this.currentSubmitter.name = response.fullName;
                this.currentSubmitter.office = response.department;
                this.currentSubmitter.phoneNumber = response.phoneNumber;
                this.currentSubmitter.faxNumber = '';
                this.currentSubmitter.emailAddress = response.emailAddress;
            })
                .catch(() => {
                this.currentSubmitter.name = '';
                this.currentSubmitter.office = '';
                this.currentSubmitter.phoneNumber = '';
                this.currentSubmitter.faxNumber = '';
                this.currentSubmitter.emailAddress = '';
            });
        }
    },
    created() {
        if (this.userIsEmployee || this.userIsAdministrator) {
            this.loadOrganizations();
        }
        else if (this.$route.name !== 'Car Fax Request' && this.$route.name !== 'Vehicle Inspection Request' && this.$route.name !== 'Forms') {
            UsersService.getUserAccount(this.$store.getters.user.id)
                .then(response => {
                this.currentSubmitter.name = response.fullName;
                this.currentSubmitter.office = response.department;
                this.currentSubmitter.phoneNumber = response.phoneNumber;
                this.currentSubmitter.faxNumber = '';
                this.currentSubmitter.emailAddress = response.emailAddress;
            })
                .catch(() => {
                this.currentSubmitter.name = '';
                this.currentSubmitter.office = '';
                this.currentSubmitter.phoneNumber = '';
                this.currentSubmitter.faxNumber = '';
                this.currentSubmitter.emailAddress = '';
            });
        }
    }
});
